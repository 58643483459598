/**
 * ・閲覧環境判定
 * ・SP版フッター
 */

/**
 * 閲覧環境判定
 * @namespace
 */
var mediaQuery = {
	set: function(){
		this.BREAKPOINT = 812.01;//（仮）
		this.winW = $(window).width();
		this.winH = window.innerHeight;
	},

	main: function(){
		this.set();
		this.init();
	},

	init: function(){
		var _self = this;

		$(window).on('resize orientationchange', function(){
			_self.set();
		});
	},

	/**
	 * SPレイアウト判定
	 * （画面幅基準）
	 * @return {bool} SP表示になる場合true
	 */
	isLayoutSp: function(){
		if(mediaQuery.winW<mediaQuery.BREAKPOINT){
			return true;
		}
		return false;
	},

	/**
	 * SP端末判定
	 * （UA基準）
	 * @return {bool} iPhone/Android（Mobile含む）の場合true
	 */
	isDeviceSp: function(){
		var ua = navigator.userAgent.toLowerCase();
		var target = {
			iphone: /iphone/i,
			android: /android /i,
			mobile: /mobile/i
		};
		if((target.iphone.test(ua)) || (target.android.test(ua) && target.mobile.test(ua) )){
			return true;
		}
		return false;
	},

	/**
	 * タブレット端末判定
	 * （UA基準）
	 * @return {bool} iPad/Android（Mobile含まない）の場合true
	 */
	isDeviceTab: function(){
		var ua = navigator.userAgent.toLowerCase();
		var target = {
			ipad: /ipad/i,
			android: /android /i,
			mobile: /mobile/i
		};
		if((target.ipad.test(ua)) || (target.android.test(ua) && !target.mobile.test(ua) )){
			return true;
		}
		return false;
	},

	/**
	 * 表示の向き判定
	 * @return {bool} ブラウザが縦長表示の場合true
	 */
	isPortrait: function(){
		if(this.winW < this.winH){
			return true;
		}
		return false;
	}
};


/**
 * SP版フッター
 * @namespace
 */
var spFooter = {
	init: function(){
		this.$nav = $('.jsFooterNav');
		this.$list = this.$nav.find('li');
		this.listNum = this.$list.length;
		this.listArr = [];						//リスト横幅格納用
		//リスト間余白
		this.buffer = parseInt(this.$nav.find('li').css('margin-right')) + parseInt(this.$nav.find('li').css('padding-right'));
	},

	/**
	 * 各パーツ横幅取得
	 */
	set: function(){
		this.width = this.$nav.width();
		for(var i=0; i<this.listNum; i++){
			this.listArr.push(this.$list.eq(i).outerWidth(true));
		}
		//リスト配置
		this.layoutList();
	},

	main: function(){
		this.init();
		this.set();

		var _self = this;
		$(window).on('resize orientationchange', function(){
			if(mediaQuery.isLayoutSp()) {
				_self.$list.removeClass('isBreak');
				_self.set();
			}
		});
	},

	/**
	 * 適切な位置で改行されるように調整
	 */
	layoutList: function(){
		var total = 0;
		for(var i=0; i<this.listNum; i++){
			total += this.listArr[i];
			if(total-this.buffer > this.width){
				this.$list.eq(i-1).addClass('isBreak');
				total = this.listArr[i];
			}
		}
	}

};


/**
 * グローバルナビゲーション
 * @namespace
 */
var globalNav = {
	init: function(){
//		this.$side = $('.topSide');
		this.$nav = $('.modGlobalNav');
		this.$navParent = $('.jsGlobalNav');
		this.$navLink = this.$navParent.find('a');
		this.$navChild = $('.modGlobalNavLower');
		this.$navBtn = $('.jsGlobalNavBtn');
	},
	toggle : function(){
		if(!this.$nav.hasClass('isOpen')){
			this.open();
		}else{
			this.close();
		}
	},
	open : function(){
//		this.$side.removeClass('isShow').addClass('isHide');
		this.$nav.removeClass('isClose').addClass('isOpen');
		this.$navParent.addClass('isVisible');
	},
	close : function(){
//		this.$side.removeClass('isHide').addClass('isShow');
		this.$nav.removeClass('isOpen').addClass('isClose');
		this.$navParent.removeClass('isVisible');
		this.$navLink.removeClass('isActive');
		this.$navChild.removeClass('isActive');
	},
	main: function(){
		this.init();

		var _self = this;
		_self.$navBtn.on('click', function(){
			_self.toggle();
		});

		//第二階層展開
		_self.$navLink.each(function(index, el) {
			$(this).on('click', function(e){
				if ((mediaQuery.isDeviceTab() || mediaQuery.isLayoutSp()) && $(this).closest('li').find('.modGlobalNavLower').length) {
					e.preventDefault();
				}

				_self.$navLink.removeClass('isActive');
				_self.$navChild.removeClass('isActive');
				$(this).next('ul').addClass('isActive');
				$(this).addClass('isActive');
			});
		});

		$('body')
			.on('click', function(e){
				if (!$(e.target).closest(_self.$nav).length) {
					_self.close();
				}
			})
		;
	}
};



/**
 * 高さ揃え
 * @namespace
 */
function FlattenItemList($list, exprItem, columns){
	this.$list   = null;
	this.$items  = null;
	this.columns = 0;
	this.init($list, exprItem, columns);
};
FlattenItemList.prototype.init = function($list, exprItem, columns){
	this.$list = $($list);
	this.$items = this.$list.find(exprItem);
	this.columns = Math.max(0, columns) || 0;
	this.initEvents();
	this.layout();
};
FlattenItemList.prototype.initEvents = function(){
	$(window)
		.on('resize.FlattenItemList load.FlattenItemList', $.proxy(this.onChange, this))
	;
};
FlattenItemList.prototype.layout = function(){
	var columns = mediaQuery.isLayoutSp() ? 2 : this.columns;
	var groups = [];
	var currentGroup = {
		maxHeight : 0,
		items : []
	};

	this.$items.css({ 'min-height' : '' }).each(function(index, node){
		if (index % columns == 0) {
			currentGroup = {
				maxHeight : 0,
				items : []
			};
			groups.push(currentGroup);
		}

		var $node = $(node);
		currentGroup.maxHeight = Math.max(currentGroup.maxHeight, $node.height());
		currentGroup.items.push($node);
	});

	$.each(groups, function(index, group){
		$.each(group.items, function(index, $node){
			$node.css({ 'min-height' : group.maxHeight + 'px' });
		});
	});
};
FlattenItemList.prototype.onChange = function(e){
	this.layout();
};




/**
 * ダイアログ
 * @namespace
 */
function ModDialog(node){
	this.$node = null;
	this.init(node);
};
ModDialog.prototype.init = function(node){
	this.initNode(node);
	this.initEvents();
};
ModDialog.prototype.initNode = function(node){
	this.$node = $(node);
	this.$node.data('ModDialog', this);
};
ModDialog.prototype.initEvents = function(){
	this.$node
		.on('click.ModDialog', $.proxy(this.onNodeClick, this))
		.on('click.ModDialog', '.modDialogClose', $.proxy(this.onCloseClick, this))
		.on('webkitAnimationEnd AnimationEnd animationend', $.proxy(this.onAnimationEnd, this))
	;
};
ModDialog.prototype.open = function(){
	this.$node.addClass('jsShown').removeClass('jsHidden');
	var scrollTop = $(window).scrollTop();
	$('.pageFrame').addClass('jsInactive').scrollTop(scrollTop);
	$('body').addClass('jsModal');
};
ModDialog.prototype.close = function(){
	this.$node.addClass('jsHidden');
	var scrollTop = $('.pageFrame').scrollTop();
	$('.pageFrame').removeClass('jsInactive');
	$('body').removeClass('jsModal');
	$(window).scrollTop(scrollTop);
};
ModDialog.prototype.onAnimationEnd = function(e){
	if (this.$node.is('.jsHidden') && $(e.target).is(this.$node)) {
		this.$node.removeClass('jsShown').removeClass('jsHidden');
	}
};
ModDialog.prototype.onNodeClick = function(e){
	if ($(e.target).closest(this.$node).length && !$(e.target).closest('.modDialogContent').length) {
		e.preventDefault();
		this.close();
	}
};
ModDialog.prototype.onCloseClick = function(e){
	e.preventDefault();
	this.close();
};



/**
 * ローカルナビ
 * @namespace
 */
function ModLocalNav(node){
	this.$node     = null;
	this.isEnabled = false;
	this.init(node);
};
ModLocalNav.prototype.CLASSNAME_OPENED   = 'jsOpened';
ModLocalNav.prototype.SELECTOR_ITEM      = '.modLocalNavBody > ul > li > a';
ModLocalNav.prototype.SELECTOR_CURRENT   = ModLocalNav.prototype.SELECTOR_ITEM + '.current';
ModLocalNav.prototype.SELECTOR_INCURRENT = ModLocalNav.prototype.SELECTOR_ITEM + ':not(.current)';
ModLocalNav.prototype.SELECTOR_OPENED    = ModLocalNav.prototype.SELECTOR_ITEM + '.' + ModLocalNav.prototype.CLASSNAME_OPENED;
ModLocalNav.prototype.init = function(node){
	this.$node = $(node);
	this.checkEnable();
	this.checkScroll();
	this.initEvents();
};
ModLocalNav.prototype.initEvents = function(){
	this.$node
		.on('click', this.SELECTOR_ITEM, $.proxy(this.onClick, this))
	;
	$(window)
		.on('load.ModLocalNav resize.ModLocalNav', $.proxy(this.onResize, this))
		.on('load.ModLocalNav resize.ModLocalNav', $.proxy(this.onScroll, this))
	;
	$(window)
		.on('scroll.ModLocalNav', $.proxy(this.onScroll, this))
	;
};
ModLocalNav.prototype.reset = function(){
	this.$node.removeClass(this.CLASSNAME_OPENED);
	this.$node.find('.jsOpened').removeClass(this.CLASSNAME_OPENED);
	this.$node.find(this.SELECTOR_CURRENT).closest('li').stop().css({ height : this.$node.find(this.SELECTOR_CURRENT).outerHeight() });
	this.$node.find(this.SELECTOR_INCURRENT).closest('li').stop().css({ height : 0 });
};
ModLocalNav.prototype.checkEnable = function(force){
	if (mediaQuery.isLayoutSp()) {
		this.enable(force);
	} else {
		this.disable(force);
	}
};
ModLocalNav.prototype.enable = function(force){
	if (force || !this.isEnabled) {
		this.isEnabled = true;
		this.reset();
	}
};
ModLocalNav.prototype.disable = function(force){
	if (force || this.isEnabled) {
		this.isEnabled = false;
		this.$node.removeClass(this.CLASSNAME_OPENED);
		this.$node.find('.' + this.CLASSNAME_OPENED).removeClass(this.CLASSNAME_OPENED);
		this.$node.find(this.SELECTOR_CURRENT).closest('li').stop().css({ height : '' });
		this.$node.find(this.SELECTOR_INCURRENT).closest('li').stop().css({ height : '' });
	}
};
ModLocalNav.prototype.checkScroll = function(){
	if (!mediaQuery.isLayoutSp()) {
		var scrollTop    = $(window).scrollTop() + $('#site-header').outerHeight();
		var nodePosition = this.$node.offset().top;

		if (scrollTop >= nodePosition) {
			this.$node.addClass('jsFixed');
		} else {
			this.$node.removeClass('jsFixed');
		}
	}
};
ModLocalNav.prototype.toggleAll = function(node){
	var $node = $(node);
	if (!this.$node.is('.' + this.CLASSNAME_OPENED)) {
		this.open();
	} else {
		if ($node.is('.' + this.CLASSNAME_OPENED)) {
			if ($(node).hasClass('hasChildren')) {
				this.close();
			}
		} else {
			this.$node.find(this.SELECTOR_OPENED)
				.each($.proxy(function(index, node){
					this.closeChildren(node);
				}, this))
			;
			this.openChildren(node);
		}
	}
};
ModLocalNav.prototype.open = function(){
	this.$node.addClass(this.CLASSNAME_OPENED);
	this.openChildren(this.$node.find(this.SELECTOR_CURRENT));
	this.$node.find(this.SELECTOR_INCURRENT).each($.proxy(function(index, a){
		this.closeChildren(a);
	}, this));
};
ModLocalNav.prototype.close = function(){
	this.$node.removeClass(this.CLASSNAME_OPENED);
	this.$node.find('.' + this.CLASSNAME_OPENED).removeClass(this.CLASSNAME_OPENED);
	this.$node.find(this.SELECTOR_CURRENT).closest('li').stop().animate({ height : this.$node.find(this.SELECTOR_CURRENT).outerHeight() }, 300);
	this.$node.find(this.SELECTOR_INCURRENT).closest('li').stop().each(function(index, li){
		var $li = $(li);
		$li.animate({ height : 0 }, 300);
	});
};
ModLocalNav.prototype.openChildren = function(node){
	var $node = $(node);
	var $li = $node.parent().stop();

	$node.addClass(this.CLASSNAME_OPENED);
	var currentHeight = $li.height();
	var toHeight = $li.css({ height : '' }).height();
	$li.css({ height : currentHeight }).animate({ height : toHeight }, 300, function(){
		$(this).css({ height : '' });
	});
};
ModLocalNav.prototype.closeChildren = function(node){
	var $node = $(node);
	var $li = $node.parent().stop();

	$node.removeClass(this.CLASSNAME_OPENED);
	var currentHeight = $li.height();
	var toHeight = $li.find('> a').outerHeight();
	$li.css({ height : currentHeight }).animate({ height : toHeight }, 300);
};
ModLocalNav.prototype.onClick = function(e){
	if (this.isEnabled) {
		if (!this.$node.is('.' + this.CLASSNAME_OPENED) || $(e.currentTarget).hasClass('hasChildren')) {
			e.preventDefault();
		}
		this.toggleAll(e.currentTarget);
	}
};
ModLocalNav.prototype.onResize = function(e){
	this.checkEnable(e.type === 'load');
};
ModLocalNav.prototype.onScroll = function(e){
	this.checkScroll();
};



function ModSlide(node) {
	this.$node    = null;
	this.$body    = null;
	this.$items   = null;
	this.$nav     = null;
	this.current  = -1;
	this.timer    = 0;
	this.interval = 3600 + 4000; // [transion-duration] + [display time]
	this.init(node);
};
ModSlide.prototype.init = function(node){
	this.initNode(node);
	this.initNav();
	this.initEvents();

	this.layout();
	this.select(0);
};
ModSlide.prototype.initNode = function(node){
	this.$node  = $(node);
	this.$body  = this.$node.find('.modSlideBody');
	this.$items = this.$body.find('> li');
	this.$nav   = $('<ul class="modSlideNav"></ul>');

	if (this.$items.length > 1) {
		this.$node.append(this.$nav);
	}
};
ModSlide.prototype.initNav = function(){
	this.$nav.empty();
	for (var i = 0, n = this.$items.length; i < n; i++) {
		var $link = $('<li><a href="javascript:void(0)" data-ModSlide="' + i + '">' + (i + 1) + '</a></li>');
		this.$nav.append($link);
	}
};
ModSlide.prototype.initEvents = function(){
	this.$nav
		.on('click', 'a', $.proxy(this.onClick, this))
	;
	this.$items.find('img')
		.on('load', $.proxy(this.onLoad, this))
	;

	$(window)
		.on('resize.ModSlide load.ModSlide', $.proxy(this.onResize, this))
	;
};
ModSlide.prototype.layout = function(){
	var height = 0;
	this.$items.each(function(index, node){
		height = Math.max(height, $(node).css({ height : '' }).height());
	});
	this.$items.css({ height : height });
	this.$body.css({ height : height });
};
ModSlide.prototype.clearTimer = function(){
	if (this.timer) {
		clearTimeout(this.timer);
		this.timer = 0;
	}
};
ModSlide.prototype.setTimer = function(){
	this.clearTimer();
	if (this.$items.length > 1) {
		this.timer = setTimeout($.proxy(this.next, this), this.interval);
	}
};
ModSlide.prototype.select = function(index){
	var _index = (index % this.$items.length + this.$items.length) % this.$items.length;
	if (this.current != _index && this.$items.length > 1) {
		this.clearTimer();

		if (this.current >= 0) {
			this.$items.eq(this.current).removeClass('isCurrent');
			this.$nav.find('a').eq(this.current).removeClass('isCurrent');
		}

		this.current = _index;
		this.$items.eq(this.current).addClass('isCurrent');
		this.$nav.find('a').eq(this.current).addClass('isCurrent');

		if (this.$node.hasClass('autoplay')) {
			this.setTimer();
		}
	}
};
ModSlide.prototype.prev = function(){
	this.select(this.current - 1);
};
ModSlide.prototype.next = function(){
	this.select(this.current + 1);
};
ModSlide.prototype.onClick = function(e){
	e.preventDefault();
	this.select(parseInt($(e.currentTarget).attr('data-ModSlide') || '0', 10));
};
ModSlide.prototype.onResize = function(e){
	this.layout();
};
ModSlide.prototype.onLoad = function(e){
	this.layout();
};



function ScrollContainer($node){
	this.$node       = null;
	this.base        = '';
	this.ignore      = '.modDialogOpener,.jsNoScroll';
	this.duration    = 400;
	this.delayOnLoad = 1000 / 60 * 10;
	this.offsetSP    = 20;
	this.offsetPC    = 30;
	this.init($node);
};
ScrollContainer.prototype.init = function($node){
	this.initNode($node);
	this.initBase();
	this.initEvents();
};
ScrollContainer.prototype.initBase = function(){
	this.base = location.href;
};
ScrollContainer.prototype.initNode = function($node){
	this.$node = $($node);
};
ScrollContainer.prototype.initEvents = function(){
	$(document)
		.on('click.ScrollContainer', '[href]', $.proxy(this.onClick, this))
	;

	// IE等で、onloadの後にブラウザによるスクロール位置の調整が行われることがあるため、
	// 遅延等の処理を重ねがけしている
	var onLoad = $.proxy(function(){
		this.onLoad();
		$(window).one('scroll.ScrollContainer', $.proxy(this.onLoad, this));
		setTimeout($.proxy(this.onLoad, this), this.delayOnLoad);
	}, this);

	$(onLoad);
	$(window).on('load.ScrollContainer', onLoad);
};
ScrollContainer.prototype.getScrollPosition = function($target){
	if ($target.length) {
		var targetTop       = $target.offset().top;
		var headerHeight    = $('#site-header').outerHeight()     || 0;
		var navHeight       = $('.modLocalNavBody').outerHeight() || 0;
		var navButtonHeight = $('.modGlobalNavBtn').outerHeight() || 0;
		var scrollToSP      = targetTop - navButtonHeight - this.offsetSP;
		var scrollToPC      = targetTop - headerHeight - navHeight - this.offsetPC;

		if (window.mediaQuery && mediaQuery.isLayoutSp()) {
			return scrollToSP;
		} else {
			return scrollToPC;
		}
	} else {
		return 0;
	}
};
ScrollContainer.prototype.scrollTo = function(hashOrPosition, animateSkip){
	switch (typeof hashOrPosition) {
		case 'string':
			this.scrollToHash(hashOrPosition, animateSkip);
			return true;
		break;
		case 'number':
			if (animateSkip) {
				this.$node.stop().prop({ scrollTop : hashOrPosition });
			} else {
				this.$node.stop().animate({ scrollTop : hashOrPosition }, this.duration);
			}
			return true;
		break;
	}
};
ScrollContainer.prototype.scrollToNode = function($target, animateSkip){
	if ($target.length) {
		return this.scrollTo(this.getScrollPosition($target), animateSkip);
	}
};
ScrollContainer.prototype.scrollToHash = function(hash, animateSkip){
	if (hash.match(/^#[-\w]*$/)) {
		var name = hash.replace(/^#/, '');
		var $target = $(hash + ',[name="' + name + '"]').eq(0);
		if ($target.length || hash == '#top') {
			if (!$target.length && hash == '#top') {
				$target = this.$node;
			}
			return this.scrollToNode($target, animateSkip);
		}
	}
};
ScrollContainer.prototype.normalize = function(href){
	return href.replace(/(\/)(index\.html)?(\?[^#]*?)?(#.*)?$/, '$1$3$4');
};
ScrollContainer.prototype.getRequestURL = function(href){
	return this.normalize(href).replace(/#.*$/, '');
};
ScrollContainer.prototype.onClick = function(e){
	var $link = $(e.currentTarget);
	if (!$link.is(this.ignore)) {
		var base = this.getRequestURL(this.base);
		var href = this.normalize($link.prop('href'));

		if (href.indexOf(base + '#') == 0) {
			var hash = $link.prop('hash');
			if (this.scrollToHash(hash)) {
				e.preventDefault();

				if (history.pushState) {
					history.pushState(null, null, hash);
				}
			}
		}
	}
};
ScrollContainer.prototype.onLoad = function(){
	var hash = location.hash;
	this.scrollToHash(hash, true);
};



$(function(){
	mediaQuery.main();
	spFooter.main();

	var isTopPage = $('body').is('.contentTop');

	if (!isTopPage) {
		globalNav.main();
	}



	$('body').addClass('jsPageLoaded');


	// 下層ページを見たらトップのアニメーション変更
	if (location.pathname != '/') {
		if (window.sessionStorage) {
			sessionStorage.setItem('isAnimateSkip', true);
		}
	}


	/**
	 * 「↑TOP」の位置制御
	 */
	var $scrollContainer = $('html,body');
	var $toTopContainer = $('.modMainFooter .modBackTo');
	var $toTop = $toTopContainer.find('a');
	if ($toTop.length) {
		function checkPositionBackTo() {
			var windowBottom = ($scrollContainer.is('html,body') ? $(window).scrollTop() + window.innerHeight : $scrollContainer.height());
			var containerBottom = $toTopContainer.offset().top + $toTopContainer.height();
			if (windowBottom >= containerBottom) {
				$toTop.addClass('jsStoped');
			} else {
				$toTop.removeClass('jsStoped');
			}
		};
		$(window).on('scroll resize load', checkPositionBackTo);
		$scrollContainer.on('scroll', checkPositionBackTo);
		checkPositionBackTo();
	}

	$('.modLocalNav').each(function(index, node){
		new ModLocalNav(node);
	});

	$('.modLinkList').each(function(index, node){
		new FlattenItemList(node, '> li > a > dl > dt', 4);
		new FlattenItemList(node, '> li > a > dl > dd', 4);
	});

	$('.modDialog').each(function(index, node){
		new ModDialog(node);
	});

	$('.modSlide').each(function(index, node){
		new ModSlide(node);
	});

	if (!isTopPage) {
		new ScrollContainer($scrollContainer);

		$('html')
			.on('click.modDialog', '.modDialogOpener', function(e){
				var $link = $(e.currentTarget);
				var hash = $link.get(0).hash;
				var $dialog = $(hash);
				if ($dialog.length && $dialog.data('ModDialog')) {
					e.preventDefault();
					$dialog.data('ModDialog').open();
				}
			})
		;

		if (location.hash && location.hash != '#!') {
			var $dialog = $(location.hash);
			if ($dialog.length && $dialog.data('ModDialog')) {
				$dialog.data('ModDialog').open();
			}
		}
	}
});
